import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { calendar_util } from 'client-lib/src/lib/utils/helpers';
import InputLabel from '../inputCommonElements/InputLabel.ts';
import THEMES from '../../styles/themes/app';
import TextInput from '../TextInput/TextInput';
import Calendar from '../Calendar/Calendar';
import IconButton from '../Button/IconButton';
import DetectClickOutside from '../../components/Common/DetectClickOutside';

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const IconPlacement = styled.div`
  position: relative;
  height: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const CalendarContainer = styled.div`
  position: absolute;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  border: 1px solid ${THEMES.BORDER_COLOR};
  border-radius: 4px;
  left: 0;
  padding: 20px;
  z-index: 1;
  bottom: 100%; /* Posiciona el calendario encima del input */
  margin-bottom: 8px;
  ${(props) => !props.hideBottomSpace && 'margin-top: -32px;'}
`;

// TODO comb through and make sure everything matches Select
const SelectDate = ({
  label,
  onChange,
  placeholder,
  error,
  helperText,
  value,
  disabled,
  customContainerStyle,
  customLabelStyle,
  customInputStyle,
  dataTestId,
  hideBottomSpace,
  calendarProps,
  closeCalendarOnChange,
  allowPlaceholder,
  isRequired,
  customCalendarStyle,
  ...props
}) => {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [inputType, setInputType] = useState(
    allowPlaceholder ? 'text' : 'date'
  );

  let date;
  // This allows blank values for calendar
  if (!value) {
    date = new Date();
  } else if (typeof value === 'string') {
    date = calendar_util.dateYYYYMMDDtoDateObj(value);
  } else {
    const start = calendar_util.dateYYYYMMDDtoDateObj(value?.[0]);
    const end = calendar_util.dateYYYYMMDDtoDateObj(value?.[1]);
    date = [start, end];
  }

  return (
    <Container
      customContainerStyle={customContainerStyle}
      css={(props) => props?.customContainerStyle?.(props)}
    >
      {label && (
        <InputLabel
          error={error}
          disbled={disabled}
          customLabelStyle={customLabelStyle}
          css={(props) => props?.customLabelStyle?.(props)}
          isRequired={isRequired}
        >
          {label}
        </InputLabel>
      )}

      <IconPlacement>
        {!disabled ? (
          <IconContainer>
            <IconButton
              size="sm"
              onClick={() => setCalendarOpen(!calendarOpen)}
            >
              <i className="ri-calendar-line" />
            </IconButton>
          </IconContainer>
        ) : null}
      </IconPlacement>
      <TextInput
        type={value ? 'date' : inputType}
        required // placing required true b/c firefox will render an X to clear the date if optional, we don't want that.
        value={Array.isArray(value) ? value[1] : value}
        onChange={onChange}
        onClick={(e) => e.preventDefault()} // also required for Firefox, because apparently they REALLY want us to use their native calendar.
        placeholder={placeholder}
        error={error}
        helperText={helperText}
        disabled={disabled}
        data-testid={dataTestId}
        customInputStyle={(props) => `
          padding-right: 32px;
          ::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
          }
          ${customInputStyle?.(props)}
        `}
        hideBottomSpace={hideBottomSpace}
        {...(allowPlaceholder ? { onBlur: () => setInputType('text') } : {})}
        {...(allowPlaceholder ? { onFocus: () => setInputType('date') } : {})}
        {...props}
      />
      {calendarOpen && (
        <DetectClickOutside
          onClickOutside={() => calendarOpen && setCalendarOpen(false)}
        >
          <CalendarContainer
            customCalendarStyle={customCalendarStyle}
            css={(props) => props?.customCalendarStyle?.(props)}
            hideBottomSpace={hideBottomSpace}
          >
            <Calendar
              value={date}
              onChange={(value) => {
                onChange({
                  target: { value: value.toISOString().split('T')[0] },
                });
                if (closeCalendarOnChange) setCalendarOpen(false);
              }}
              size="sm"
              {...calendarProps}
            />
          </CalendarContainer>
        </DetectClickOutside>
      )}
    </Container>
  );
};

SelectDate.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabled: PropTypes.bool,
  customContainerStyle: PropTypes.func,
  customLabelStyle: PropTypes.func,
  customInputStyle: PropTypes.func,
  dataTestId: PropTypes.string,
  hideBottomSpace: PropTypes.bool,
  calendarProps: PropTypes.object,
  closeCalendarOnChange: PropTypes.bool,
  allowPlaceholder: PropTypes.bool,
  isRequired: PropTypes.bool,
  customCalendarStyle: PropTypes.func,
};

SelectDate.defaultProps = {
  label: null,
  placeholder: null,
  error: false,
  helperText: '',
  value: null,
  disabled: false,
  customContainerStyle: null,
  customLabelStyle: null,
  customInputStyle: null,
  dataTestId: null,
  hideBottomSpace: false,
  calendarProps: {},
  closeCalendarOnChange: false,
  allowPlaceholder: false,
  isRequired: false,
  customCalendarStyle: () => {},
};

export default SelectDate;
